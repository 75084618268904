<template>
  <el-container>
    <el-row style="width: 100%;">
      <el-col :xs="2" :sm="2" :md="5" :lg="5" class="burger left_column">
        <el-aside style="width: initial; height: 100%;">
          <el-image :src="require('@/assets/logo.svg')" class="logo hidden-sm-and-down"></el-image>
          <Burger />
        </el-aside>
      </el-col>
      <el-col :xs="22" :sm="22" :md="19" :lg="19" class="right_column">
        <el-main>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-space>
                <h2>{{ video.title }}</h2>
                <el-button type="text" @click="onLogout()">
                  <el-image :src="require('@/assets/exit.svg')" class="exit"></el-image>
                </el-button>
              </el-space>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col>
              <h3 class="doc">Видео урок</h3>
              <el-divider></el-divider>
              <el-card shadow="hover">
                <div class="video">
                  <span v-html="video.link"></span>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-main>
        <el-footer>
          <span>Полититка конфедициальности</span><br>
          <span>let.tobe.happy@gmail.com</span>
        </el-footer>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>

import Burger from '../components/Burger.vue';
import 'element-plus/lib/theme-chalk/display.css';
import axiosInstance from '../services/AxiosTokenInstance';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  LOGOUT_ACTION,
  GET_USER_TOKEN_GETTER,
  LOADING_SPINNER_SHOW_MUTATION,
} from '../store/storeconstants';

export default {
  data() {
    return {
      video: {
        title: '',
        link: '',
      },
    };
  },
  computed: {
    ...mapGetters('auth', {
      token: GET_USER_TOKEN_GETTER,
    }),
  },
  components: {
    Burger,
  },
  mounted() {
    this.showLoading(true);
    axiosInstance
        .get(`https://betterlife-school.ru/app/api/me/lila/video/${this.$route.params.id}`)
        .then((response) => {
          this.formatVideo(response.data);
          this.showLoading(false);
        })
        .catch(() => {
          this.showLoading(false);
        });
  },
  methods: {
    ...mapActions('auth', {
      logout: LOGOUT_ACTION,
    }),
    ...mapMutations({
      showLoading: LOADING_SPINNER_SHOW_MUTATION,
    }),
    formatVideo(video) {
      this.video.title = video.title;
      this.video.link = video.link;
    },
    onLogout() {
      this.logout();
      this.$router.replace('/login');
    }
  },
};
</script>

<style>
@media only screen and (min-width: 992px) {
  .bm-menu {
    min-width: 20%;
    margin-top: 13%;
  }
  .bm-burger-button {
    display: none;
  }
  .bm-cross-button {
    display: none;
  }
}
.courses_card .el-card {
  background-color: #F4F4F6;
  border-radius: 15px;
}
</style>

<style scoped>
.logo {
  position: fixed;
  padding: 10px;
  margin-bottom: 20px;
  width: 15%;
  left: 2%;
  top: 5%;
}
.el-main {
  height: 100%;
}
.el-space {
  display: flex;
  justify-content: space-between;
}
.el-container {
  background: #ffffff;
}
.el-header, .el-footer {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
}
body > .el-container {
  margin-bottom: 40px;
}
.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}
.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
h2 {
  margin-bottom: 0;
}
.el-divider {
  margin-top: 0;
}
</style>
